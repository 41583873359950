import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import Questions from "../../components/Questions";
import Button from "../../components/Button";

import Savings from "../../img/snowbirds-save.png";

import styles from "./index.module.scss";

const SnowBirds = () => {
  return (
    <Layout>
      <Background themeType={"light"}>
        <Hero type={"snowbirds"} backgroundType={"dark"}>
          <Col xs={12} sm={7}>
            <h1>Snowbirds save with Shift Connect.</h1>
            <Button
              to="/snowbirds-contact"
              className={styles.button}
              color={"yellow"}
            >
              Lets Chat
            </Button>
          </Col>
          <Col xs={12} sm={5}>
            <img
              src={Savings}
              class="img-fluid"
              alt="Ipad Showing Savings"
            ></img>
          </Col>
        </Hero>
        <Container>
          <Row class="row full-width-row">
            <Col className={styles.firstSection} xs={12}>
              <h1>Experts at Your Fingertips</h1>
              <p>
                We are a celebrated Canadian foreign exchange and global
                payments agency dedicated to helping you navigate the space
                completely stress-free. Our team of experts is ready to support
                you with all of your International payment needs from start to
                finish. Shift Connect makes foreign exchange simple, affordable,
                and stress-free for Canadian snowbirds. Trust that you and your
                funds are in expert hands with Shift.
              </p>
              <p>
                {" "}
                <br /> Our customer service relationship doesn’t end once you’re
                onboarded. Rather, we’re always available to assist you as much
                or as little as you like. This process can be completely
                collaborative or totally independent, that’s up to you. Our
                Snowbird currency exchange program was made with you in mind.
              </p>
            </Col>
          </Row>
        </Container>
        <div className={styles.containerImg}>
          <Row class="row full-width-row">
            <Col className={styles.secondSection} xs={12}>
              <h1>
                Stop wasting money on
                <br /> high foreign exchange rates.
              </h1>
              <Button
                to="/snowbirds-contact"
                className={styles.button}
                color={"yellow"}
              >
                Let's Chat
              </Button>
            </Col>
          </Row>
        </div>
        <Container class="container-fluid ">
          <Row class="row full-width-row">
            <Col className={styles.firstSection} xs={12} md={4}>
              <h2>Save Time</h2>
              <p>
                Say goodbye to time-consuming bank visits and waiting on hold.
                Not only is our team highly responsive, but our industry-leading
                global payments platform allows snowbirds to send or receive
                money from around the world in just a few simple clicks.
              </p>
            </Col>
            <Col className={styles.firstSection} xs={12} md={4}>
              <h2>Save Money</h2>
              <p>
                Shift Connect offers countless opportunities for our community
                members to save money on foreign exchange. From highly
                competitive exchange rates and no hidden fees to our unmatched
                industry knowledge, our snowbird currency exchange platform is
                unparalleled.
              </p>
            </Col>
            <Col className={styles.firstSection} xs={12} md={4}>
              <h2>Reduce Stress</h2>
              <p>
                We take all of the guesswork out of foreign exchange and global
                payments so all you have to do is sit back and save. US banking
                for Canadians can be stressful. We have a finger on the pulse
                and we are committed to making your experience completely
                stress-free.
              </p>
            </Col>
          </Row>
        </Container>

        <div className={styles.container}>
          <Row class="row full-width-row">
            <Col md={6} className={styles.imgColumn} />
            <Col md={6} className={styles.textColumn}>
              <h2>We’re here for you. </h2>
              <p>
                Headquartered in Calgary, we have team members around the globe.
                We serve both corporate and private clients, empowering them to
                exchange funds in 130 currencies, hold funds on account in 60 of
                those currencies, and make local payments without sending wires
                in 30 countries including UK, Europe, US, and Canada.
                <br />
                <br />
                We pride ourselves on our ability, expertise, and reliability.
                Our team works to ensure you feel secure, supported, and
                satisfied every step of the way. We're always in your corner.
              </p>
            </Col>
          </Row>
          <Row class="row full-width-row">
            <Col md={6} className={styles.textColumn2}>
              <h2>Joining the Shift community. </h2>
              <p>
                At Shift Connect we offer a variety of services to snowbirds
                with a wide range of needs. From simple global money transfers
                to in-depth foreign exchange strategies, our team of experts is
                ready to serve you. You’ve worked hard for your money and we
                wanted to ensure it’s never going to waste.
                <br />
                <br />
                Click below to learn more about how we can support you and get
                in touch to start the process. We look forward to working with
                you.
              </p>
            </Col>
            <Col md={6} className={styles.imgColumn2} />
          </Row>
        </div>
        <Container>
          <Row>
            <Col className={styles.firstSection} xs={12}>
              <h2>Let’s do this.</h2>
              <p>
                If you’d like to partner with one of our experts, drop your
                information below and we’ll be in touch right away. Or, if you’d
                like to get the ball rolling on your first transaction,
                you’re more than welcome to register and start your first
                transaction today. The choice is yours and we’re here to support
                you.
              </p>
              <Button
                to="/snowbirds-contact"
                className={styles.button}
                color={"purple"}
              >
                Talk to an Expert
              </Button>
            </Col>
          </Row>
        </Container>
        <Questions />
      </Background>
    </Layout>
  );
};

export default SnowBirds;
